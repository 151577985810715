@import '~include-media/dist/include-media';

.menuDesktop {
  display: flex;

  li {
    padding-inline: 10px !important;

    &::after {
      inset-inline: 10px !important;
    }
  }
}

.menuMobile {
  display: none;
}

.antMenu {
  display: flex;
}

.menuItem {
  margin: 0px 6px !important;
  border-bottom: none !important;
}

.menuIcon {
  display: block;
  margin-top: 15px;
  font-size: 25px;
  border: 1px solid;
  border-radius: 5px;
  padding: 2.5px;
}

.userMenu {
  margin: 0px 10px !important;
}

.loginButtons {
  display: flex;
  gap: 1rem;
  margin-left: 10px;
}

@include media('<=1072px') {
  .userMenu {
    margin: 0px 6px !important;
  }

  .menuDesktop {
    display: none;
  }

  .menuMobile {
    display: flex;
    float: right;
    margin-top: 17px;
  }
}
