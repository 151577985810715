.picker {
  position: relative;
}

.inputWrapper {
  display: flex;
  gap: 5px;

  input {
    width: 88px;
  }
}

.swatch {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 99;
}
