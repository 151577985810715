@import '~include-media/dist/include-media';

$soft-border: solid 1px rgba(0, 0, 0, 0.2);

.component-schema-form {
  position: relative;

  .formAction {
    padding: 30px;
    border-top: $soft-border;
  }

  .control-label {
    padding-bottom: 4px;
    line-height: 20px;
  }

  .field-array {
    margin-bottom: 20px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

    .control-label-array {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.row-element {
  background: rgba(0, 0, 0, 0.03);

  &.odd {
    background: rgba(0, 0, 0, 0.01);
  }

  .row-element-form {
    display: flex;
    flex-direction: row;
    border-bottom: none;

    &:last-child {
      border-bottom: $soft-border;
    }

    & > .form-group {
      display: flex;
      flex: 1;
      margin: 0;

      fieldset {
        display: flex;
        width: 100%;
        border: none;

        &:after {
          clear: both;
        }

        .form-group {
          float: left;
          padding-right: 2%;
        }
      }
    }

    .array-template-action {
      display: flex;
      flex-direction: row;
      width: 5em;
      align-items: center;
      justify-content: center;

      button {
        margin: 2px;
        width: 30px;
        height: 30px;

        i {
          font-size: 18px;

          &.fa {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.component-schema-form {
  form {
    & > .form-group > .field-content-input > fieldset {
      border: solid 1px rgba(0, 0, 0, 0.12);
      background: #fff;
      margin: 10px 0 20px 0;
      padding: 0;

      & > .form-group {
        margin: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: $soft-border;
        max-width: calc(100vw - 150px);
        overflow: auto;

        @include media('<=tablet') {
          max-width: calc(100vw - 60px);
        }

        fieldset {
          border: none;
        }

        &:last-child {
          border-bottom: none;
        }

        & > label {
          border-right: $soft-border;
          padding: 2em;
          width: 30%;
          font-size: 14px;
          line-height: 1.93;
          color: rgba(0, 0, 0, 0.8);
        }

        &.field-boolean,
        &.field-string,
        &.field-integer {
          .field-content-input {
            margin: 2em;
          }
        }

        &.field-boolean {
          .field-content-input {
            .control-label {
              display: none;
            }
          }

          .primary-checkbox {
            width: 100%;
            // padding: 22px;
            padding-bottom: 0;

            & > label {
              margin: 0;
            }
          }
        }
      }

      // field array
      .form-group.field-array {
        & > label.control-label {
          display: none;
        }
      }

      .field-content-input {
        width: auto;
        flex: 1;
      }

      .form-group-array {
        width: 100%;

        & > label {
          display: flex;
          padding: 30px;
          width: 100%;
          font-size: 14px;
          line-height: 1.93;
          color: rgba(0, 0, 0, 0.8);
          justify-content: space-between;

          button.component-ak-basic-button {
            .component-ak-action-button-wrap {
              height: 27px;
              line-height: 27px;
            }
          }

          &.control-label-add-bottom {
            padding-top: 5px;
            justify-content: flex-end;
          }
        }

        .row-element-form {
          border: none;
        }

        .row-element {
          padding: 30px;
          margin: 0 30px;

          & > .form-group > .control-label {
            display: none;
          }

          &.row-element-hide-label {
            fieldset > .form-group > .control-label {
              display: none;
            }
          }
        }
      }

      // Custom Field Template -> field object
      .form-group.field-object {
        .field-content-input {
          fieldset {
            & > .control-label {
              display: none;
            }

            .form-group.field {
              &.field-string,
              &.field-integer {
                padding: 15px 30px;
                margin: 0px;
              }
            }
          }
        }
      }
    }

    .form-control {
      font-size: 16px;
      border-radius: 2px;
      height: 40px;

      &:focus {
        box-shadow: 0 0 0 2px rgb(0, 211, 178, 0.2);
        border-color: var(--primary-color);
      }
    }
  }
}

// Style for ArrayFieldTemplateForm
.template-form {
  form {
    & > .form-group > .field-content-input > fieldset {
      .form-group.field-array {
        & > label.control-label {
          display: block;
        }
      }

      .form-group-array {
        .row-element {
          padding: 2em 1em 2em 2em;
          margin: 2em;

          &.row-element-hide-label {
            fieldset > .form-group > .control-label {
              display: block;
            }
          }
        }
      }
    }
  }

  .row-element {
    .row-element-form {
      & > .form-group {
        fieldset {
          .form-group {
            float: unset;
          }
        }
      }
    }
  }
}

// Style for ArrayFieldTemplateTable
.template-table {
  form {
    & > .form-group > .field-content-input > fieldset {
      .form-group-array {
        > div:first-of-type {
          .array-template-action {
            margin-top: 20px;
          }
        }

        .row-element-form {
          @for $i from 1 through 8 {
            &.columns-#{$i} {
              fieldset > .form-group {
                width: calc(100% / #{$i});
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 10px !important;

                .control-label {
                  top: -1.2em;
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .wizard_drawing_notes {
    .control-label-array {
      padding: 13px 20px !important;
    }

    .row-element {
      padding: 5px !important;
      margin: 0px 5px !important;

      .row-element-form {
        flex-direction: column;

        .array-template-action {
          width: 100%;
          justify-content: flex-end;
          padding: 0 8px;
        }
      }
    }

    .row-element-form {
      fieldset {
        display: flex !important;
        flex-direction: column !important;

        .control-label {
          display: block !important;
        }

        .form-group {
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .wizard_revisions {
    .control-label-array {
      padding: 13px 20px !important;
    }

    .row-element {
      padding: 5px !important;
      margin: 0px 5px !important;

      .row-element-form {
        flex-direction: column;

        .array-template-action {
          width: 100%;
          justify-content: flex-end;
          padding: 0 8px;
        }
      }
    }

    .row-element-form {
      fieldset {
        display: flex !important;
        flex-direction: column !important;

        .control-label {
          display: block !important;
        }

        .form-group {
          width: 100% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .wizard_title_block {
    .field-object {
      > .control-label {
        padding: 1rem !important;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .wizard_bom {
    .control-label-array {
      padding: 13px 20px !important;
    }

    .row-element {
      padding: 5px !important;
      margin: 0px 5px !important;

      .row-element-form {
        flex-direction: column;

        .array-template-action {
          width: 100%;
          justify-content: flex-end;
          padding: 0 8px;
        }
      }
    }

    .row-element-form {
      fieldset {
        display: flex !important;
        flex-direction: column !important;

        .control-label {
          display: block !important;
        }

        .form-group {
          width: 100% !important;
        }
      }
    }
  }
}

.ant-select-dropdown .ant-select-item-option-content {
  white-space: inherit;
}
