.fileButton {
  clear: both;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;

  &:hover {
    background-color: transparent;
  }
}

.guestModalBody {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-size-adjust: 100%;
  text-align: left;
  height: 124px;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    text-decoration: none;
    color: rgb(49, 122, 226);
  }
}

.ads {
  padding: 1rem;
}
