@import '~include-media/dist/include-media';

.oauthWrapper {
  text-align: center;
  margin-bottom: 20px;
}

.oauthButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.microsoftButton {
  margin-left: 10px;
  height: 41px;
  width: 215px;
  font-size: 15px;
  font-weight: 600;
  color: #5e5e5e !important;
  background: #ffffff !important;
  border: 1px solid #8c8c8c;

  img {
    float: left;
  }
}

@include media-context(
  (
    'custom': 640px,
  )
) {
  @include media('<custom') {
    .oauthButtons {
      flex-direction: column;
      align-items: center;
    }

    .microsoftButton {
      margin-left: 0px;
      margin-top: 10px;
      width: 100%;
    }

    .googleButton {
      width: 100% !important;
    }
  }
}
