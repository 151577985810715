.wrapper {
    padding: 1rem;
    border: 1px solid #eaeaea;
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .container {
    padding: 1rem 2rem;
    border: 1px solid #eaeaea;
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
  }  
  .left h3{
    font-size: 14px;
    font-weight: 400;
  }