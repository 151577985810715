.wapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.leftWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.rightWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.buttonSideMenu {
  transition: 0.5s ease;
}

.buttonSideMenuClose {
  margin-left: 0px;
}

@media only screen and (max-width: 770px) {
  .root {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .buttonSideMenu {
    display: none;
  }
}
