@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?h40f9o');
  src:  url('./fonts/icomoon.eot?h40f9o#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?h40f9o') format('truetype'),
    url('./fonts/icomoon.woff?h40f9o') format('woff'),
    url('./fonts/icomoon.svg?h40f9o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icomoon-iconCableSplit:before {
  content: "\e926";
}
.icomoon-description-24-px:before {
  content: "\e931";
}
.icomoon-ruler-and-pencil-cross:before {
  content: "\e932";
}
.icomoon-save-24-px:before {
  content: "\e933";
}
.icomoon-shopping-list:before {
  content: "\e934";
}
.icomoon-view-360:before {
  content: "\e92f";
}
.icomoon-login-header-cables3:before {
  content: "\e92e";
}
.icomoon-login-header-assemblies:before {
  content: "\e924";
}
.icomoon-login-header-bookmark:before {
  content: "\e925";
}
.icomoon-login-header-connectors:before {
  content: "\e935";
}
.icomoon-login-header-create-assembly:before {
  content: "\e92a";
}
.icomoon-login-header-faqs:before {
  content: "\e92b";
}
.icomoon-login-header-notifications:before {
  content: "\e92c";
}
.icomoon-login-header-quotes:before {
  content: "\e92d";
}
.icomoon-cube-outline:before {
  content: "\e91e";
}
.icomoon-delete:before {
  content: "\e91f";
}
.icomoon-arrow-next:before {
  content: "\e920";
}
.icomoon-copy:before {
  content: "\e921";
}
.icomoon-group-6706:before {
  content: "\e922";
}
.icomoon-redo:before {
  content: "\e966";
}
.icomoon-cube-small:before {
  content: "\e923";
}
.icomoon-ic-share:before {
  content: "\e928";
}
.icomoon-insert-drive-file-18-px:before {
  content: "\e929";
}
.icomoon-ic-alarm-add-black-24-px:before {
  content: "\e91d";
}
.icomoon-icon-play:before {
  content: "\e91c";
}
.icomoon-newsletter-signup:before {
  content: "\e91b";
}
.icomoon-client-review:before {
  content: "\e91a";
}
.icomoon-consumer-b:before {
  content: "\e918";
}
.icomoon-path-1157_1818:before {
  content: "\e919";
}
.icomoon-contact-us-b:before {
  content: "\e917";
}
.icomoon-video-b:before {
  content: "\e916";
}
.icomoon-path-1334:before {
  content: "\e914";
}
.icomoon-message:before {
  content: "\e913";
}
.icomoon-login:before {
  content: "\e912";
}
.icomoon-about-us:before {
  content: "\e911";
}
.icomoon-contact-us:before {
  content: "\e910";
}
.icomoon-customersolutions:before {
  content: "\e90f";
}
.icomoon-news-events:before {
  content: "\e90e";
}
.icomoon-faqs:before {
  content: "\e90d";
}
.icomoon-forum:before {
  content: "\e90c";
}
.icomoon-videos:before {
  content: "\e90b";
}
.icomoon-industrysolutions:before {
  content: "\e90a";
}
.icomoon-signup:before {
  content: "\e909";
}
.icomoon-close:before {
  content: "\e908";
}
.icomoon-linkedin .path1:before {
  content: "\e905";
  color: rgba(0, 0, 0, 0.8);
}
.icomoon-linkedin .path2:before {
  content: "\e906";
  margin-left: -1.0078125em;
  color: rgb(255, 255, 255);
}
.icomoon-linkedin .path3:before {
  content: "\e907";
  margin-left: -1.0078125em;
  color: rgb(255, 255, 255);
}
.icomoon-connector:before {
  content: "\e904";
}
.icomoon-create-assembly:before {
  content: "\e903";
}
.icomoon-connector-library:before {
  content: "\e902";
}
.icomoon-search:before {
  content: "\e901";
}
.icomoon-logo:before {
  content: "\e900";
}
.icomoon-customersolutions-b:before {
  content: "\e915";
}
.icomoon-users:before {
  content: "\e915";
}
