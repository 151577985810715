.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.titleText {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.item {
  white-space: normal;
}
