.selectWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.templateSettingsForm {
  form {
    > [class~='form-group'] {
      > [class~='field-content-input'] {
        fieldset {
          > [class~='form-group'] {
            > [class~='field-content-input'] {
              padding: 1rem 2rem;
            }
          }
        }
      }
    }
  }
}
