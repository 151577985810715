.container {
    padding: 1rem 2rem;
    border: 1px solid #eaeaea;
    max-height: calc(100vh - 12rem);
    overflow-y: auto;
  }
  
  .header {
    text-align: center;
    margin-bottom: 1rem;
  }
  