@import '~include-media/dist/include-media';

:root {
  --primary-color: #00d3b2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

.ant-drawer-body {
  padding: 0;
}

.ant-collapse-header {
  font-weight: 600;
}

.ant-descriptions-item-label {
  font-weight: 500;
}

.ant-collapse-content-box {
  padding: 2px !important;
}

@include media-context(
  (
    'custom': 640px,
  )
) {
  @include media('<custom') {
    .signup-form,
    .login-form {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #e6fff7; //Change the existing color to `unset`
}

.ant-input-prefix {
  margin-inline-end: 8px !important;
}
