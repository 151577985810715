@import '~include-media/dist/include-media';

.header {
  display: flex;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 1px 10px #ddd;
  margin-bottom: 0;
}

.logo {
  margin-top: 3px;
}

@include media('<=tablet') {
  .header {
    padding: 0 10px;
  }

  .logo {
    margin-top: 6px;
  }
}
