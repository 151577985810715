.header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.profileInfo {
  padding: 20px 50px;
  border: 2px solid #6686b5;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}

.statusInfo {
  border: 2px solid #a3a3a3;
}

.planInfo {
  border: 2px solid #ffb570;
}

.leftContent {
  padding-top: 10px;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

  button {
    min-width: 210px;
    min-height: 35px;
  }
}

.link {
  font-weight: 600;
}

.loading {
  max-height: fit-content !important;
}
