@import '~include-media/dist/include-media';

.filterCondition {
  width: 30%;
  padding: 0 0 0 20px;
  display: flex;
  position: relative;
  z-index: 1;
}

.filterConditionText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f9f9f9;
  flex: 1;
  font-size: 14px;
  line-height: 1.27;
  letter-spacing: -0.2px;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
}

.filterValueSelect {
  width: 100%;
  height: 32px;
}

.filterInput {
  justify-content: center;
  flex: 1;
  font-size: 14px;
  line-height: 1.27;
  letter-spacing: -0.2px;
  text-align: left;
  color: rgba(0, 0, 0, 0.54);
  height: 32px;
  border: solid 1px rgba(0, 0, 0, 0.08);
  width: 100%;

  &:focus {
    outline-color: var(--primary-color);
  }
}

.filterInputRange {
  @extend .filterInput;
  width: 45%;
}

.filterInputRangeTo {
  @extend .filterInput;
  display: flex;
  background: white;
  align-items: center;
  width: 10%;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  border: none;
  justify-content: center;
}

@include media('<=tablet') {
  .filterCondition {
    width: 100%;
    padding: 0px;
    margin-bottom: 10px;
  }

  .filterInputRangeTo {
    background: none;
  }
}
