@import '~include-media/dist/include-media';

.signBox {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #e0e0e0;
  max-width: 624px;
  margin: 2em auto;
  padding: 4em 0;
}

.title {
  text-align: center;
  margin-bottom: 25px;
}

.signUpButton {
  width: 100%;
}

.oauthWrapper {
  text-align: center;
  margin-top: 10px;
}

.oauthButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.loginWrapper {
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
}

.loginLink {
  cursor: pointer;
  font-weight: 600;
  border-bottom: 2px solid var(--primary-color);
}

@include media-context(
  (
    'custom': 640px,
  )
) {
  @include media('<custom') {
    .signBox {
      border: none;
      box-shadow: none;
      margin: 0px -24px;
      padding: 20px 0;
      border-radius: 0px;
    }
  }
}
