.card {
  border: 1px solid rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: all 0.1s ease-in;
  min-height: 300px;

  &:hover {
    border-color: var(--primary-color);
  }
}

.cardBody {
  cursor: pointer;
  position: relative;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: inherit;

  &:hover {
    .cardOverlay {
      visibility: visible;
    }
  }
}

.cardImage {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.cardOverlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  height: 100%;
  width: 100% !important;
  visibility: hidden;
  padding: 12px;
  font-size: 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.cardOverlayTable {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: solid 1px white;
  display: flex;
  align-items: flex-start;
}

.cardOverlayTableLabel {
  flex: 3;
  text-align: left;
  position: relative;
  &:before {
    display: block;
    content: ':';
    position: absolute;
    right: 0;
    top: -2px;
    font-size: 18px;
    text-align: right;
  }
}

.cardOverlayTableValue {
  text-align: right;
  flex: 2;
}
