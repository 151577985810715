@import '~include-media/dist/include-media';

.filterTree {
  padding-top: 1em;
  margin-left: 2em;
  max-width: 770px;
}

.filterTreeRow {
  display: flex;
  padding-left: 1.5em;
  justify-content: space-between;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.08);
    width: 100%;
    position: absolute;
    top: 40%;
    left: 0;
    z-index: 0;
    margin-top: -0.5px;
  }

  &:after {
    content: '';
    display: block;
    height: 42px;
    background: rgba(0, 0, 0, 0.08);
    width: 1px;
    position: absolute;
    top: -14px;
    left: 0;
    z-index: 0;
  }

  &:last-child::after {
    height: 31px;
  }
}

.filterLabel {
  height: 32px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 2px solid var(--primary-color);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 10px;
  width: 30%;
  position: relative;
  z-index: 1;
}

.deleteButton {
  margin-left: 1rem;
}

@include media('<=tablet') {
  .filterTree {
    padding: 0px;
    margin: 0px;
  }

  .filterTreeRow {
    padding: 10px;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    &:nth-child(even) {
      background-color: #eeeeee;
    }

    &:nth-child(odd) {
      background-color: aliceblue;
    }

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  .filterLabel {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .deleteButton {
    margin-left: 0px;
  }
}
