body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* For MxGraph Tooltip */
div.mxTooltip {
  box-shadow: 3px 3px 12px #c0c0c0;
  background: #ffffcc;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  font-family: Arial;
  font-size: 8pt;
  position: absolute;
  cursor: default;
  padding: 4px;
  color: black;
}
div.mxRubberband {
  position: absolute;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #0000ff;
  background: #0077ff;
  cursor: crosshair;
}
.mxCellEditor {
  background: url(data:image/gif;base64,R0lGODlhMAAwAIAAAP///wAAACH5BAEAAAAALAAAAAAwADAAAAIxhI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8egpAAA7);
  border-color: transparent;
  border-style: solid;
  display: inline-block;
  position: absolute;
  overflow: visible;
  word-wrap: normal;
  border-width: 0;
  min-width: 1px;
  resize: none;
  padding: 0px;
  margin: 0px;
}
.mxPlainTextEditor * {
  padding: 0px;
  margin: 0px;
}
.dashedEdge {
  stroke-dasharray: 10 20;
}

.whiteEdge {
  /* https://caniuse.com/css-filters */
  filter: drop-shadow(-1px 1px 0px rgb(128, 128, 128)) drop-shadow(1px -1px 0px rgb(128, 128, 128));
}
.cabel-dropdown .ant-select-arrow {
  color: black !important;
}
