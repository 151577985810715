.wrapper {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.16);
  border: 1px solid #e0e0e0;
  max-width: 624px;
  margin: 2em auto;
  padding: 2em 0 3px;
}

.linkBack {
  font-size: 16px;
  font-weight: 600;
}

.linkBackIcon {
  margin-right: 5px;
}

.header {
  margin-top: 1em;
}

.body {
  margin-top: 1em;
}
