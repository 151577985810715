$primary-theme-color: var(--primary-color);

.partsNavigation {
  margin-bottom: 20px;
}

.navigationMenu {
  font-size: 15px;
  font-weight: bold;
}

.buttonAction {
  cursor: pointer;
  text-decoration: underline;
  padding-left: 0.5em;
}

.navigationLink {
  display: grid;
  gap: 5px;
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: solid 1px rgba(0, 0, 0, 0.08);
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  color: blue;

  & > div {
    cursor: pointer;
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      font-weight: 500;
    }
  }
}
