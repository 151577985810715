@import '~include-media/dist/include-media';

@include media('<=tablet') {
  .layout {
    margin-top: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  margin-bottom: 15px;

  h1 {
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
    flex: 1;
  }

  .headerRight {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 5px;
  }
}

.pagination {
  text-align: center;
  margin-top: 10px;
}

.addItemButton {
  margin-bottom: 10px;
}
