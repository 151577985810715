.switch {
  background-color: var(--primary-color);
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.pagination {
  text-align: right;
  margin-top: 1rem;
}

.table_content {
  overflow: auto;
  td {
    max-width: 500px;
    a {
      width: 100%;
      display: inline-block;
      button {
        width: 100%;
        span {
          width: 100%;
          white-space: break-spaces;
          text-align: start;
        }
      }
    }
  }
}
