.plansSection {
  padding: 20px 0px;
  text-align: center;
}

.card {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 2px #02d4db;
  }
}

.active {
  box-shadow: 0 0 0 2px #00c4cc;
}

.bestValue {
  position: absolute;
  top: -13px;
  left: 40.5%;
  z-index: 1;

  span {
    background-color: #f84856;
    color: white;
    padding: 5px 10px;
    font-weight: 600;
  }
}

.planTitle {
  font-weight: 600;
}

.planPrice {
  font-weight: 600;
}
