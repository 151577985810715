.findreplacebtn {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #d9d9d9;
  max-width: fit-content;
  padding: 3px 10px;
  cursor: pointer;
  background: #fbfbfb;
}

.modal_main_box_sort {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 18px 0px 14px;

  input {
    height: 26px;
  }

  .find_ok_button {
    display: flex;
    gap: 10px;
  }
  .lable {
    font-size: 10px;
    margin: 0px;
  }
}
