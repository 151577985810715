@import '~include-media/dist/include-media';

.converter-img {
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.04);
  max-width: 100%;
}

@include media('<=tablet') {
  .converter-img {
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@include media('<=tablet') {
  .x3dom-canvas {
    height: 300px !important;
  }
}

.x3dom-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  strong {
    font-size: 0;
  }

  span,
  span::after {
    position: absolute;
    width: 20px !important;
    height: 20px;
    border: 4px solid var(--primary-color);
    border-top-color: transparent;
    border-radius: 50%;
  }
  span {
    -webkit-animation: lds-rolling 1s linear infinite;
    animation: lds-rolling 1s linear infinite;
    top: 50%;
    left: 50%;
    margin-left: -10px;
  }
}

@keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes lds-rolling {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
