.pdfWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    .pdfPageWrapper {
      display: flex;
    }
  }
}

.pdfPageWrapper {
  display: none;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px;
}
