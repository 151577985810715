.wrapper {
  text-align: center;
}

.error {
  color: red;
}

.summaryWrapper {
  margin-top: 2em;

  .summaryTitle {
      font-weight: 600;
  }

  p {
      display: flex;
      justify-content: space-between;
  }
}

.totalAmount {
  border-top: 1px solid gray;
  font-weight: 600;
  padding-top: 5px;
}

.agreement {
  margin: 10px 0;
}