@import '~include-media/dist/include-media';

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  margin-bottom: 15px;
  gap: 1rem;
  
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h2 {
    font-size: 27px;
  }

  @include media('<=tablet') {
    flex-direction: column-reverse;

    h2 {
      font-size: 22px;
    }
  }
}

.search {
  max-width: 250px;
  margin-top: 2px;

  @include media('>phone', '<=tablet') {
    width: 100%;
    max-width: 100%;
  }
}

.card {
  cursor: pointer;
  border: 2px solid #efefef;
  box-shadow: 2px 3px rgba(0, 0, 0, 0.08);
  color: black;
  text-align: center;
  transition: all 0.1s ease-in;
  border-radius: 8px;
  font-weight: 600;

  &:hover {
    color: blue;
    border-color: blue;
    box-shadow: 2px 3px blue;
  }
}
