.container {
  display: flex;
  // flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > span {
    font-size: 13px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  span:first-child {
    font-size: 15px;
  }
}
