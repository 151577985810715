.menu {
  margin-bottom: 10px;
  z-index: 999;
  position: absolute;
  top: 23px;
  left: 335px;
  border: none;
  font-size: 12px;
  text-align: center;

  span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 730px) {
  .menu {
    top: 80px;
    left: 15px;
  }
}
