.layout {
  margin: -70px -70px -24px;
  padding: 10px;
  background: white;
  min-height: calc(100vh - 170px);
}

.tabAssemblies {
  margin-top: 10px;
}

.tabEngineeringDrawing {
  position: relative;
}

.content {
  display: flex;
}

.mainContent {
  width: calc(100% - 465px);
}

.mainContentFull {
  width: calc(100%);
}

.toolDrawerLeft {
  margin-top: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 10px;
  overflow-y: auto;
  height: calc(100vh - 170px);
  width: 210px;
}

.toolDrawerRight {
  margin-top: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  overflow-y: auto;
  height: calc(100vh - 131px);
  width: 235px;
}

.toolDrawerClose {
  width: 0px !important;
  overflow: auto;
  margin-right: 0px;
}

@media only screen and (max-width: 769px) {
  .layout {
    margin: -25px;
  }
}

@media only screen and (max-width: 1024px) {
  .mainContent {
    width: calc(100%);
  }

  .toolDrawerLeft {
    display: none;
  }

  .toolDrawerRight {
    display: none;
  }
}
