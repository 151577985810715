.filterNode {
  height: 32px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 2px solid var(--primary-color);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 10px;
  cursor: pointer;
}

.appliedFilters {
  margin-top: 0px !important;
}

.noFilters {
  text-align: center;
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: -10px;
}
