@import "~include-media/dist/include-media";

.breadcrumb {
  position: absolute;
  top: 78px;
  left: 50px;
}

@include media("<=tablet") {
  .breadcrumb {
    left: auto;
  }
}
