.shareWrapper {
  margin-bottom: -20px;
}

.shareLink {
  display: flex;
  justify-content: space-between;

  input {
    margin-right: 8px;
  }
}

%border {
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 10px;
}

.title {
  @extend %border;
}

.divider {
  margin-top: 15px;
  @extend %border;
}

.shareByEmail {
  margin-top: 10px;
}
