@import '~include-media/dist/include-media';

@include media('<=1540px') {
  .wizard-header-add-component {
    &.full {
      .ant-carousel {
        width: 82%;
      }
    }

    .ant-carousel {
      width: 76%;
    }
  }
}

@include media('>1540px') {
  .wizard-header-add-component {
    &.full {
      .ant-carousel {
        width: 85%;
      }
    }

    .ant-carousel {
      width: 82%;
    }
  }
}

.wizard-header-add-component {
  .wizard-header-add-component__left {
    margin-right: 25px;
    padding: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 200px;
    gap: 10px;
  }

  display: flex;
  width: 100%;
  z-index: 999;
  background: rgba(103, 103, 103, 0.94);
  color: #fff;
  margin-top: 8px;
  justify-content: space-around;
  flex-wrap: wrap;

  .ant-carousel {
    height: 100%;

    .slick-slider {
      height: 100%;
      padding: 10px 0;

      .suggestion-box {
        display: inline-block;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        margin-right: 10px;
        background: black;
        border-radius: 16px;
        padding: 10px;
      }
    }

    .slick-list {
      padding: 0px;
    }

    .slick-prev {
      color: blue;
      background: white;
      height: 100%;
      top: 0%;
      margin-top: 0px;
      border: none;

      svg {
        width: 18px;
        height: inherit;
      }
    }

    .slick-next {
      color: blue;
      background: white;
      height: 100%;
      top: 0%;
      margin-top: 0px;
      border: none;

      svg {
        width: 18px;
        height: inherit;
      }
    }

    .slick-disabled {
      color: #4f4f4f;
    }
  }
}

.navigation-wrapper {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  padding: 5px;
  width: 99% !important;
  margin: 0px 6px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;

    p {
      margin-top: 0;
      margin-bottom: 1em;
    }

    span {
      text-decoration: underline;
      color: blue;
      margin-right: 1em;
    }

    .btn-nav {
      cursor: pointer;
    }
  }

  .content-wrapper {
    display: flex;
    flex-wrap: wrap;

    p {
      text-decoration: underline;
      cursor: pointer;
      color: blue;
      padding: 0px 5px;
      margin-top: 0;
      margin-bottom: 1em;
    }
  }
}
