.wrapper {
  padding: 1rem;
  border: 1px solid #eaeaea;
  max-height: calc(100vh - 12rem);
  overflow-y: auto;
}
.external_approvals_titel {
  font-size: 30px;
  font-weight: 600;
  margin-left: 1.5rem;
}
