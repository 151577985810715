@import '~include-media/dist/include-media';

.wrapper {
  margin-top: 50px;
  padding: 0 50px;
}

.content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

@include media('<=tablet') {
  .wrapper {
    padding: 0 5px;
    margin-top: 5px;
  }
}
