.actionButton {
  display: flex;
  justify-content: flex-end;
}

.fileContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttonWrapper {
  display: flex;
  gap: 1rem;
}

// .external_approvals_title {
//   font-size: 30px !important;
//   font-weight: 600 !important;
// }
